import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from "../components/layout";
import SchemaTable from "../components/schemaTable/SchemaTable";
import ParamsTable from "../components/paramsTable/ParamsTable";
import dets from "../../dets.js";
import Uris from "../components/uris/Uris";
import Seo from '../components/seo';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <Seo title="Shape" mdxType="Seo" />
    <h1>{`Shape`}</h1>
    <p>{`Get a shape.`}</p>
    <h2 id={dets.shape.schemaId}>Shape Schema</h2>
    <p>{`See `}<a parentName="p" {...{
        "href": "https://gtfs.org/schedule/reference/#shapestxt"
      }}>{`gtfs.org`}</a></p>
    <section className="minHeightSection">
      <h2 id={dets.shape.routes[0].id}>{dets.shape.routes[0].title}</h2>
      <p>{dets.shape.routes[0].desc}</p>
      <h3>{`Params`}</h3>
      <ParamsTable params={dets.shape.routes[0].params} mdxType="ParamsTable" />
      <h3>{`Example`}</h3>
      <h4>{`Request`}</h4>
      <pre><code parentName="pre" {...{}}>{`/api/shapebytripid/3698576.40.10-401-e19-1.1529.I
`}</code></pre>
  <details>
    <summary>
    <h4>Response</h4>
    </summary>
        <pre><code parentName="pre" {...{
            "className": "language-json"
          }}>{`{
  "results": [
    {
      "shape_id": "10-401-e19-1.1529.I",
      "shape_pt_lat": 53.2644141217376,
      "shape_pt_lon": -9.08318879575356,
      "shape_pt_sequence": 1,
      "shape_dist_traveled": 0
    },
    {
      "shape_id": "10-401-e19-1.1529.I",
      "shape_pt_lat": 53.2642334542461,
      "shape_pt_lon": -9.08427849837113,
      "shape_pt_sequence": 2,
      "shape_dist_traveled": 75.21
    }
    // ... more shapes
  ]
}
`}</code></pre>
  </details>
    </section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      